import { mf, msg } from '../message-functions'

/**
  <span style="color:#85c1dc;">**EE:**</span>
  Eelmine

  <span style="color:#85c1dc;">**FI:**</span>
  Edellinen

  <span style="color:#85c1dc;">**RU:**</span>
  Предыдущий
*/
/* @__NO_SIDE_EFFECTS__ */
const m1922 = msg(
  mf`Eelmine`,
  mf`Edellinen`,
  mf`Предыдущий`,
)

export { m1922 as 'global.previous' }