import { mf, msg } from '../message-functions'

/**
  <span style="color:#85c1dc;">**EE:**</span>
  Järgmine

  <span style="color:#85c1dc;">**FI:**</span>
  Seuraava

  <span style="color:#85c1dc;">**RU:**</span>
  Следующий
*/
/* @__NO_SIDE_EFFECTS__ */
const m710 = msg(
  mf`Järgmine`,
  mf`Seuraava`,
  mf`Следующий`,
)

export { m710 as 'global.next' }