import { mf, msg } from '../message-functions'

/**
  <span style="color:#85c1dc;">**EE:**</span>
  Go to slide

  <span style="color:#85c1dc;">**FI:**</span>
  Go to slide

  <span style="color:#85c1dc;">**RU:**</span>
  Перейти к слайду
*/
/* @__NO_SIDE_EFFECTS__ */
const m263 = msg(
  mf`Go to slide`,
  mf`Go to slide`,
  mf`Перейти к слайду`,
)

export { m263 as 'w.global.goto_slide' }